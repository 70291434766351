<template>
  <!-- apps START -->
  <div class="apps">
    <!-- apps:headline:description START -->
    <div class="headline-description">
      <h1 v-html="$t('sms.apps.headline')"></h1>
      <div
        class="description"
        v-html="`${$t('sms.apps.redirect.first')} ${count} ${$t('sms.apps.redirect.second')}`"
      ></div>
    </div>
    <!-- apps:headline:description END -->
  </div>
  <!-- apps END -->
</template>

<script>
export default {
  name: 'apps',
  data() {
    return {
      /**
       * Redirect
       */
      count: 5,
    };
  },
  created() {
    // redirect to google play / app store
    this.redirectCountDown();
  },
  methods: {
    redirectCountDown() {
      const timeInterval = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(timeInterval);
          if (this.$device.ios) {
            window.location = 'https://apps.apple.com/at/app/google-maps-transit-essen/id585027354';
          } else {
            window.location = 'https://play.google.com/store/apps/details?id=com.google.android.apps.maps';
          }
        }
      }, 1000);
      // window.location.href = redirect;
    },
  },
};
</script>
